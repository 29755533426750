<div>
  <div>
    <app-header [shop]="shop" [table]="table" [useBasket]="false"></app-header>
  </div>

  <div class="status-screen">
    <ng-container *ngIf="orderTicket != null">

      <ng-container *ngIf="orderTicket.orderTime == null">
        <div>
          <b>Bestelling is niet geplaatst, neem snel contact op met <a href="mailto:support@bestelmandje.be?subject=Bestelling%20met%20id%20{{orderTicket.id}}%20is%20besteld%20maar%20er%20is%20een%20probleem%20opgetreden%20">Bestelmandje (Klik hier)</a></b>
        </div>
      </ng-container>

      <ng-container *ngIf="orderTicket.orderTime != null">
        <h3 class="orders-title">Status van je bestelling</h3>
        <div class="timeline">
          <ul>
            <li>
              <div class="bullet" [ngClass]="{'enabled': orderTicket.orderTime != null}"></div>
              <div class="time"><h3><ng-container *ngIf="orderTicket.orderTime != null">{{getTime(orderTicket.orderTime)}}</ng-container></h3></div>
              <div class="desc" [ngClass]="{'dark': orderTicket.orderTime != null}">
                <h3>Bestelling geplaatst</h3>
                <h4>De bestelling is geplaatst</h4>
              </div>
            </li>
            <li>
              <div class="bullet" [ngClass]="{'enabled': orderTicket.confirmedDeliveryTime != null}"></div>
              <div class="time"><h3><ng-container *ngIf="orderTicket.confirmedDeliveryTime != null">{{getTime(orderTicket.confirmTime)}}</ng-container></h3></div>
              <div class="desc" [ngClass]="{'dark': orderTicket.confirmedDeliveryTime != null}">
                <h3><ng-container *ngIf="orderTicket.confirmedDeliveryTime != null">Bestelling is bevestigd</ng-container><ng-container *ngIf="orderTicket.confirmedDeliveryTime == null">Wachten op bevestiging</ng-container></h3>
                <h4><ng-container *ngIf="orderTicket.confirmedDeliveryTime != null">De zaak bereidt je bestelling</ng-container><ng-container *ngIf="orderTicket.confirmedDeliveryTime == null">Even geduld aub</ng-container></h4>
              </div>
            </li>
            <li>
              <div class="bullet" [ngClass]="{'enabled': orderTicket.kitchenTime != null}"></div>
              <div class="time"><h3><ng-container *ngIf="orderTicket.kitchenTime != null">{{getTime(orderTicket.kitchenTime)}}</ng-container></h3></div>
              <div class="desc" [ngClass]="{'dark': orderTicket.kitchenTime != null}">
                <h3>Bestelling is in keuken</h3>
              </div>
            </li>
            <li>
              <div class="bullet" [ngClass]="{'enabled': orderTicket.onDeliveryTime != null}"></div>
              <div class="time"><h3><ng-container *ngIf="orderTicket.onDeliveryTime != null">{{getTime(orderTicket.onDeliveryTime)}}</ng-container></h3></div>
              <div class="desc" [ngClass]="{'dark': orderTicket.onDeliveryTime != null}">
                <h3>Bestelling is onderweg</h3>
              </div>
            </li>
            <li>
              <div class="bullet green" [ngClass]="{'enabled': orderTicket.deliverTime != null}"></div>
              <div class="time"><h3><ng-container *ngIf="orderTicket.deliverTime != null">{{getTime(orderTicket.deliverTime)}}</ng-container></h3></div>
              <div class="desc" [ngClass]="{'dark': orderTicket.deliverTime != null}">
                <h3>Bestelling is geleverd</h3>
              </div>
            </li>
          </ul>
        </div>

        <ng-container *ngIf="orderTicket.orderTime != null">
          <div class="order-status-border-box">
            <div class="order-status-box">
              <h3 class="order-status-title"><ng-container *ngIf="orderTicket.confirmedDeliveryTime != null">{{getTime(orderTicket.confirmedDeliveryTime)}}</ng-container><ng-container *ngIf="orderTicket.confirmedDeliveryTime == null">Wachten op bevestiging, even geduld aub.</ng-container></h3>
            </div>
          </div>
        </ng-container>

      </ng-container>

      <!-- <ng-container *ngIf="orderTicket.orderTime != null">
        <div class="order-message-border-box">
          <div class="order-status-box">
            <h3 class="order-message-title">U kunt hier berichten sturen naar de zaak</h3>
          </div>
        </div>
      </ng-container>

      <ng-container *ngIf="orderTicket.orderTime != null">
        <div class="order-evaluation-border-box">
          <div class="order-status-box">
            <h3 class="order-evaluation-title">U kunt hier beoordelen na de levering</h3>
          </div>
        </div>
      </ng-container> -->

      <ng-container *ngIf="orderTicket.orderItems != null">
        <h3 class="orders-title">Bestelling</h3>

        <ng-container *ngFor="let item of orderTicket.orderItems">
          <ng-container *ngIf="item != null">
            <div class="order-item-detail underlined">
              <div>
                <ng-container *ngIf="item.product != null">
                  <p><strong>{{item.orderCount}}x <ng-container *ngIf="item.product.details != null && item.product.details.length > 0 && item.product.details[0] != null">{{item.product.details[0].name}}</ng-container></strong></p>
                  <p><strong></strong></p>
                </ng-container>

                <div class="order-item-details">

                  <ng-container *ngIf="item.variantSelections != null">
                    <ng-container *ngFor="let variantSelection of item.variantSelections">
                      <p>{{variantSelection.variant.details[0].name}}: {{variantSelection.option.details[0].name}}</p>
                    </ng-container>
                  </ng-container>

                  <ng-container *ngIf="item.supplementSelections != null">
                    <ng-container *ngFor="let supplementSelection of item.supplementSelections">
                      <p>{{supplementSelection.supplement.details[0].name}}: {{supplementSelection.product.details[0].name}}</p>
                    </ng-container>
                  </ng-container>

                  <ng-container *ngIf="item.condimentSelections != null && item.condimentSelections.length > 0">
                    <p>Condimenten</p>
                    <ng-container *ngFor="let condimentSelection of item.condimentSelections">
                      <p><ng-container *ngIf="condimentSelection.value">+</ng-container><ng-container *ngIf="!condimentSelection.value">-</ng-container> {{condimentSelection.condiment.details[0].name}}</p>
                    </ng-container>
                  </ng-container>

                  <ng-container *ngIf="item.extraSelections != null && item.extraSelections.length > 0">
                    <p>Extra's</p>
                    <ng-container *ngFor="let extraSelection of item.extraSelections">
                      <p>+ {{extraSelection.condiment.details[0].name}}</p>
                    </ng-container>
                  </ng-container>

                  <div>
                    <p>{{item.note}}</p>
                  </div>

                </div>
                <div>
                  <p class="order-ticket-sum-right"><strong>{{(item.price * item.orderCount) / 100 | currency: 'EUR'}}</strong></p>
                </div>
              </div>
            </div>
          </ng-container>
        </ng-container>
      </ng-container>

      <div class="order-item-detail underlined">
        <p class="order-ticket-sum-left">Leverings kosten</p><p class="order-ticket-sum-right"><ng-container *ngIf="orderTicket.deliveryPrice != 0">{{(orderTicket.deliveryPrice / 100) | currency: 'EUR'}}</ng-container><ng-container *ngIf="orderTicket.deliveryPrice == 0">Gratis</ng-container></p>
      </div>

      <div class="order-item-detail underlined">
        <p class="order-ticket-sum-left"><strong>Totaal</strong></p><p class="order-ticket-sum-right"><strong>{{((orderTicket.price + orderTicket.deliveryPrice) / 100) | currency: 'EUR'}}</strong></p>
      </div>

      <div class="order-item-detail underlined">
        <p class="order-ticket-sum-left">Betaling</p><p class="order-ticket-sum-right"><strong *ngIf="orderTicket.paymentStatus != null && orderTicket.paymentStatus === 'paid'">{{orderTicket.paymentMethod}}</strong><strong *ngIf="orderTicket.paymentStatus == null || orderTicket.paymentStatus !== 'paid'">cash</strong></p>
      </div>

      <div class="order-item-detail">
        <p>Notitie: <i>{{orderTicket.note}}</i></p>
      </div>


      <h3 class="orders-title">Klant gegevens</h3>
      <ng-container *ngIf="orderTicket.name">
        <div class="order-item-detail small">
          <p class="order-ticket-sum-left"><strong>{{orderTicket.name}}</strong></p>
        </div>
      </ng-container>
      <ng-container *ngIf="orderTicket.phone != null">
        <div class="order-item-detail small">
          <p class="order-ticket-sum-left">{{orderTicket.phone}}</p>
        </div>
      </ng-container>
      <ng-container *ngIf="orderTicket.email != null">
        <div class="order-item-detail small">
          <p class="order-ticket-sum-left">{{orderTicket.email}}</p>
        </div>
      </ng-container>
      <ng-container *ngIf="orderTicket.destination === 'D'">
        <ng-container *ngIf="orderTicket.streetAndNr != null">
          <div class="order-item-detail">
            <p class="order-ticket-sum-left">{{orderTicket.streetAndNr}}</p>
          </div>
        </ng-container>
      </ng-container>
      <ng-container *ngIf="orderTicket.destination === 'P'">
        <div class="order-item-detail">
          <p class="order-ticket-sum-left">U heeft gekozen om af te halen</p>
        </div>
      </ng-container>
      <ng-container *ngIf="orderTicket.destination === 'T'">
        <div class="order-item-detail">
          <p class="order-ticket-sum-left">Bestelling komt aan tafel</p>
        </div>
      </ng-container>
      <ng-container *ngIf="orderTicket.confirmedDeliveryTime != null">
        <div class="order-item-detail">
          <p class="order-ticket-sum-left">Om {{getTime(orderTicket.confirmedDeliveryTime)}}</p>
        </div>
      </ng-container>
      <ng-container *ngIf="orderTicket.confirmedDeliveryTime == null">
        <div class="order-item-detail">
          <p class="order-ticket-sum-left">Wachten op bevestiging, even geduld aub.</p>
        </div>
      </ng-container>

      <ng-container *ngIf="shop != null">
        <h3 class="orders-title">Zaak gegevens</h3>
        <div class="order-item-detail small">
          <p><strong>{{shop.name}}</strong></p>
        </div>
        <div class="order-item-detail small">
          <p>{{shop.address}}</p>
        </div>
        <div class="order-item-detail small">
          <p>{{shop.zipcode}}, {{shop.town}}</p>
        </div>
        <div class="order-item-detail">
          <p><sup>BTW </sup>{{shop.vatNr}}</p>
        </div>
        <div class="order-item-detail small">
          <p>{{shop.email}}</p>
        </div>
        <div class="order-item-detail small">
          <p>{{shop.website}}</p>
        </div>

      </ng-container>

      <h3 class="orders-title"><strong>Bestelmandje</strong></h3>
      <div class="order-item-detail small">
        <p><strong>Bestelmandje</strong></p>
      </div>
      <div class="order-item-detail small">
        <p><sup>BTW </sup>BE0772957366</p>
      </div>
      <div class="order-item-detail small">
        <p>info@bestelmandje.be</p>
      </div>
      <div class="order-item-detail">
        <p>support@bestelmandje.be</p>
      </div>


    </ng-container>
  </div>

  <div>
    <!-- <div><!-- TODO plaats in component -->
      <!-- <div class="footer-logo">
        <h3>BESTELMANDJE.BE</h3>
        <p><i>By: S-Taha-Y</i></p>
      </div>
    </div> -->
  </div>
</div>
